/**
 * @generated SignedSource<<b89a4bda7cd796a33e6a65c39997eafc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type bizProfileReviewRatings_BizProfileReviewStatisticsV2$data = {
  readonly starRating1Count: number;
  readonly starRating2Count: number;
  readonly starRating3Count: number;
  readonly starRating4Count: number;
  readonly starRating5Count: number;
  readonly " $fragmentType": "bizProfileReviewRatings_BizProfileReviewStatisticsV2";
};
export type bizProfileReviewRatings_BizProfileReviewStatisticsV2$key = {
  readonly " $data"?: bizProfileReviewRatings_BizProfileReviewStatisticsV2$data;
  readonly " $fragmentSpreads": FragmentRefs<"bizProfileReviewRatings_BizProfileReviewStatisticsV2">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "bizProfileReviewRatings_BizProfileReviewStatisticsV2"
};

(node as any).hash = "f8b2cc9af4b677adf16392dc26615d9c";

export default node;
