import CONFIG, { IS_REAL } from 'config';
import * as _amplitude from '@amplitude/analytics-browser';
import _mixpanel from 'mixpanel-browser';
import { SessionStorage } from './Storage';
import { karrotBridge } from 'bridge';
import { PluginAnalytics } from '@daangn/webview-bridge-modern/lib/plugins/Analytics';
import { getFlatObject } from 'utils/Misc';
import routes from 'routes';
import { matchPath } from 'react-router-dom';
import { prevActivity } from 'stackflow/utils';
import { initialQueries } from 'setupApp';
import type { BridgeApp, BridgeUser, BridgeRegion } from 'bridge/info';
import { checkIsAmplitudeTargetUser } from 'utils/amplitude';
import qs from 'query-string';
import { z } from 'zod';
import { captureException } from '@sentry/react';
import {
  AppsflyerEvent,
  type MarketingEventButton,
  type MarketingEventPage,
} from 'types/marketing';
import type { UserGroup } from 'store/group';
import type { UserSegment } from 'utils/segment';
import type { KarrotBridge } from '@daangn/karrotbridge';
import {
  DefaultEventProvider,
  LoggerEventParams,
  LoggerEventProperties,
  LoggerEventType,
} from 'logger/schema/eventSchema';
import { getEventProvider } from 'logger/utils';
import { EventProviderType } from 'logger/types/common';

export type EventParams = {
  name: string;
  description: string;
  [key: string]: any;
};

type BridgeLogEventParams = NonNullable<Parameters<PluginAnalytics['log']>[0]['params']>;
type BridgeLogEventType = Parameters<KarrotBridge['logEvent']>[0]['analytics']['target'];

// https://github.com/daangn/realty-stats/blob/f95ae483d2940153e1da41c7bafdc48cf442df0b/task/schedule/daily.rb#L69
// bigquery 테이블에 정의되어있는 타입들
const bigqueryDefinedScheme = z.object({
  article_id: z.coerce.number().optional(),
  page: z.coerce.number().optional(),
});

const checkEventParamKeysOverLimit = (eventParams: BridgeLogEventParams, limitLength: number) => {
  const keysOverLimit = Object.entries(eventParams).reduce(
    (acc, [key, value]) => {
      if (key.length > limitLength) {
        acc[key] = { value, keyLength: key.length };
      }
      return acc;
    },
    {} as Record<
      string,
      { value: BridgeLogEventParams[keyof BridgeLogEventParams]; keyLength: number }
    >
  );

  return Object.keys(keysOverLimit).length !== 0 ? keysOverLimit : null;
};

class LoggerClass {
  private gaInitialized = false;
  private amplitude = _amplitude;
  private mixpanel = _mixpanel;
  private user: BridgeUser | undefined = undefined;
  private segment: Partial<UserSegment> | undefined = undefined;
  private group: UserGroup | undefined = undefined;

  constructor() {
    // GA4 초기화전 log 도 쌓일 수 있도록 미리 선언
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    };
    this.amplitude.init(CONFIG.AMPLITUDE_KEY, {
      defaultTracking: false,
      minIdLength: 1,
    });
    this.mixpanel.init(CONFIG.MIXPANEL_KEY, {
      debug: !IS_REAL,
    });
  }

  initGA() {
    if (this.gaInitialized) {
      return;
    }
    this.gaInitialized = true;
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${CONFIG.GA4_TRACKING_ID}`;
    document.head.appendChild(script);

    gtag('js', new Date());
    gtag('config', CONFIG.GA4_TRACKING_ID, {
      send_page_view: false,
      campaign_source: SessionStorage.getReferrer(),
      // false여도 debug 모드가 활성화됨. (https://support.google.com/analytics/answer/7201382?hl=en#zippy=%2Cgoogle-tag-gtagjs)
      ...(!IS_REAL && {
        debug_mode: true,
      }),
    });
  }

  setUser(
    user: BridgeUser,
    {
      region,
      segment,
      group,
      isDebugUser,
    }: {
      region?: BridgeRegion;
      segment?: Partial<UserSegment>;
      group?: UserGroup;
      isDebugUser: boolean;
    }
  ) {
    this.user = user;
    this.segment = segment;
    this.group = group;

    // ga4
    const userProperties = {
      region_name: region?.name2,
      is_debug_user: isDebugUser,
      ...(segment && getFlatObject({ segment })),
      ...(group && getFlatObject({ group })),
    };

    gtag('set', {
      user_id: user.id,
      user_properties: userProperties,
    });

    // amplitude
    const identifyEvent = new this.amplitude.Identify();

    Object.entries(userProperties).forEach(([key, value]) => {
      value !== undefined && identifyEvent.set(key, value);
    });

    this.amplitude.setUserId(String(user.id));
    this.amplitude.identify(identifyEvent);

    // mixpanel
    this.mixpanel.identify(String(user.id));
    this.mixpanel.people.set(userProperties);
  }

  setDevice(app: BridgeApp) {
    this.amplitude.setDeviceId(app.deviceIdentity);
  }

  private get pageName() {
    const { name } =
      routes.find((route) =>
        matchPath(window.location.pathname, { path: route.path, exact: true })
      ) || {};

    return name || '';
  }

  /* eslint-disable no-console */
  private bridgeLog({
    target,
    name,
    params,
  }: {
    target: BridgeLogEventType;
    name: string;
    params: BridgeLogEventParams;
  }) {
    const { name: eventName, description, ...restParams } = params || {};
    const keysOverLimit = restParams ? checkEventParamKeysOverLimit(restParams, 40) : null;
    const bigqueryDefinedSchemeParseResult = bigqueryDefinedScheme.safeParse(restParams);

    if (!bigqueryDefinedSchemeParseResult.success) {
      if (!IS_REAL) {
        throw bigqueryDefinedSchemeParseResult.error;
      } else {
        captureException(bigqueryDefinedSchemeParseResult.error);
      }
    }

    if (!IS_REAL) {
      if (keysOverLimit) {
        console.groupCollapsed(
          `%c[Warn]: "${eventName}"에 40자 넘어가는 key가 있어요.`,
          'color: lime;'
        );
        console.table(keysOverLimit);
        console.groupEnd();
      }

      const eventLogTitle = `[${target}] (${name}) ${eventName ?? ''}`;
      const isInitVConsole: boolean = (window as any).__VCONSOLE_INSTANCE?.isInited;

      if (isInitVConsole) {
        console.groupCollapsed(eventLogTitle);
        description && console.log(`설명: ${description}`);
        console.log({ ...restParams });
      } else {
        console.groupCollapsed(`%c${eventLogTitle}`, `color: #ff6e1d;`);
        console.table({ description, ...restParams });
      }
      console.groupEnd();
    }

    karrotBridge.logEvent({
      analytics: {
        target,
        name,
        params: JSON.stringify(params),
      },
    });
  }

  private async firebaseLog({
    name,
    params,
  }: {
    // https://daangn.slack.com/archives/C04H5BG8M27/p1710484511517669?thread_ts=1710382385.648839&cid=C04H5BG8M27
    // V1 - show_realty / click_realty
    // V2 - shown_page_realty / shown_element_realty / clicked_realty
    name:
      | 'show_realty'
      | 'click_realty'
      | 'shown_page_realty'
      | 'shown_element_realty'
      | 'clicked_realty'
      // marketing
      | 'show_event_page'
      | 'click_event_page_normal_button';
    params: BridgeLogEventParams;
  }) {
    this.bridgeLog({
      target: 'FIREBASE',
      name,
      params,
    });
  }

  appsflyerLog(name: AppsflyerEvent) {
    this.bridgeLog({
      target: 'APPSFLYER',
      name,
      params: {},
    });
  }

  private async amplitudeLog(type: 'page_view' | 'click' | 'impression', param: EventParams) {
    const isAmplitudeTargetUser = await checkIsAmplitudeTargetUser(this.user?.id, this.segment);

    if (!isAmplitudeTargetUser) {
      return;
    }

    const { name, ...eventProperties } = param;
    const eventName = `[${type}] ${name}`;

    this.amplitude.track(eventName, eventProperties);
  }

  private mixpanelLog(type: 'page_view' | 'click' | 'impression', param: EventParams) {
    const { name, ...eventProperties } = param;
    const eventName = `[${type}] ${name}`;

    this.mixpanel.track(eventName, eventProperties);
  }

  private get commonParams() {
    return {
      referrer: SessionStorage.getReferrer(),
      sref: SessionStorage.getReferrer(),
      ref: prevActivity.get()?.name,
      page_name: this.pageName,
      segment: this.segment,
      group: this.group,
      iquery: initialQueries, // initial_queries
      pquery: qs.parse(window.location.search),
    };
  }

  private impression(paramPayload: EventParams, options: EventProviderType[]) {
    const params = getFlatObject({
      ...this.commonParams,
      ...paramPayload,
      impression: true,
    });

    for (const option of options) {
      if (option === 'ga4') {
        gtag('event', String(params.name), {
          ...params,
          event_category: 'impression',
        });
      } else if (option === 'firebase') {
        this.firebaseLog({ name: 'show_realty', params });
        this.firebaseLog({ name: 'shown_element_realty', params });
      } else if (option === 'amplitude') {
        this.amplitudeLog('impression', params);
      } else if (option === 'mixpanel') {
        this.mixpanelLog('impression', params);
      }
    }
  }

  pageView(paramPayload: EventParams, options: EventProviderType[]) {
    const params = getFlatObject({
      ...this.commonParams,
      ...paramPayload,
    });

    for (const option of options) {
      if (option === 'ga4') {
        gtag('event', 'page_view', {
          ...params,
          page_title: document.title,
          page_location: window.location.href,
          page_path: window.location.pathname,
        });
      } else if (option === 'firebase') {
        this.firebaseLog({ name: 'show_realty', params });
        this.firebaseLog({ name: 'shown_page_realty', params });
      } else if (option === 'amplitude') {
        this.amplitudeLog('page_view', params);
      } else if (option === 'mixpanel') {
        this.mixpanelLog('page_view', params);
      }
    }
  }

  private click(paramPayload: EventParams, options: EventProviderType[]) {
    const params = getFlatObject({
      ...this.commonParams,
      ...paramPayload,
    });

    for (const option of options) {
      if (option === 'ga4') {
        gtag('event', String(params.name), {
          ...params,
          event_category: 'click',
        });
      } else if (option === 'firebase') {
        this.firebaseLog({ name: 'click_realty', params });
        this.firebaseLog({ name: 'clicked_realty', params });
      } else if (option === 'amplitude') {
        this.amplitudeLog('click', params);
      } else if (option === 'mixpanel') {
        this.mixpanelLog('click', params);
      }
    }
  }

  marketingPageView(params: { post_id: MarketingEventPage }) {
    this.firebaseLog({
      name: 'show_event_page',
      params,
    });
  }
  marketingClick(params: { post_id: MarketingEventPage; button_id: MarketingEventButton }) {
    this.firebaseLog({
      name: 'click_event_page_normal_button',
      params,
    });
  }

  track<TEventName extends LoggerEventType>(
    name: TEventName,
    params: LoggerEventParams<TEventName>
  ) {
    // 1. ParamPayload 만들기
    const eventProps = LoggerEventProperties[name];

    const paramPayload: EventParams = {
      name: name,
      description: eventProps.description,
      ...params,
    };

    // 2. Provider 마다 보내주기
    const provider = eventProps.provider
      ? eventProps.provider
      : DefaultEventProvider[eventProps.type];

    const providerMapping = getEventProvider(
      'event_type' in params ? params.event_type : undefined,
      eventProps.providerMapping as { [key: string]: EventProviderType[] }
    );

    const providers = providerMapping || provider;

    // [TODO] 이벤트 전환 완료 후 제거
    // console.groupCollapsed(`%c[TRACK] "${name}"`, 'color: lime;');
    // console.table(paramPayload);
    // console.log(providers);
    // console.log('Options', eventProps.provider, DefaultEventProvider[eventProps.type]);
    // console.groupEnd();

    // 3. EventType마다 해당 이벤트 실행
    switch (eventProps.type) {
      case 'click':
        this.click(paramPayload, providers);
        break;
      case 'impression':
        this.impression(paramPayload, providers);
        break;
    }
  }
}

const Logger = new LoggerClass();

export default Logger;
