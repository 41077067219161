/**
 * @generated SignedSource<<45be1bfb684bf238aa748ddd66c5a45c>>
 * @relayHash b06d916997b748155087c03f4793767a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b06d916997b748155087c03f4793767a

import { ConcreteRequest, Query } from 'relay-runtime';
export type KontrolDeploymentProtocol = "grpc" | "http";
export type DeploymentListQuery$variables = Record<PropertyKey, never>;
export type DeploymentListQuery$data = {
  readonly kontrolDeployments: ReadonlyArray<{
    readonly endpoints: ReadonlyArray<{
      readonly backendServiceName: string;
      readonly host: string;
      readonly protocol: KontrolDeploymentProtocol;
    }>;
    readonly title: string;
  }>;
};
export type DeploymentListQuery = {
  response: DeploymentListQuery$data;
  variables: DeploymentListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "KontrolDeployment",
    "kind": "LinkedField",
    "name": "kontrolDeployments",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "KontrolDeploymentEndpoint",
        "kind": "LinkedField",
        "name": "endpoints",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "host",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "protocol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "backendServiceName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeploymentListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DeploymentListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "b06d916997b748155087c03f4793767a",
    "metadata": {},
    "name": "DeploymentListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "0f95122d11b2f869e4f70f84c7eea290";

export default node;
