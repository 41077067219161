import { HStack, Stack } from '@chakra-ui/layout';
import type { DeploymentListQuery } from '__generated__/DeploymentListQuery.graphql';
import BoxButton from 'components/Base/BoxButton';
import Text from 'components/Base/Text';
import { graphql, useLazyLoadQuery } from 'react-relay';

type Props = {
  onClick: (endpoint: string) => void;
};

const DeploymentList = ({ onClick }: Props) => {
  const { kontrolDeployments } = useLazyLoadQuery<DeploymentListQuery>(
    graphql`
      query DeploymentListQuery {
        kontrolDeployments {
          title
          endpoints {
            host
            protocol
            backendServiceName
          }
        }
      }
    `,
    {}
  );

  const apiDeployments = kontrolDeployments
    .map((deployment) => ({
      title: deployment.title,
      endpoint: deployment.endpoints.find(
        (endpoint) => endpoint.protocol === 'http' && endpoint.host.includes('karrotmarket')
      ),
    }))
    .filter((deployment) => !!deployment.endpoint);

  return (
    <Stack spacing={8}>
      <Text variant="label3Bold">Preview API 배포 목록</Text>
      <Stack spacing={4}>
        {apiDeployments.map((deployment) => (
          <HStack key={deployment.title}>
            <Text variant="label3Bold">
              {deployment.title} ({deployment.endpoint?.host})
            </Text>
            <BoxButton
              variant="primaryLow"
              flexShrink={0}
              onClick={() => onClick(`https://${deployment.endpoint?.host}`)}
            >
              적용
            </BoxButton>
          </HStack>
        ))}
      </Stack>
    </Stack>
  );
};

export default DeploymentList;
