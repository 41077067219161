import '@seed-design/stylesheet/global.css';
import '@seed-design/stylesheet/token.css';
import '@daangn/error-component/index.css';
import '@daangn/sprout-components-tabs/index.css';
import '@daangn/sprout-components-switch/index.css';
import '@daangn/sprout-components-avatar/index.css';
import '@daangn/sprout-components-callout/index.css';
import '@daangn/sprout-components-button/index.css';
import '@daangn/sprout-components-text-field/index.css';
import '@daangn/sprout-components-chips/index.css';
import '@daangn/sprout-components-radio-group/index.css';
import '@daangn/sprout-components-checkbox/index.css';
import '@daangn/sprout-components-spinner/index.css';
import '@daangn/sprout-components-dialog/index.css';
import '@daangn/sprout-components-fab/index.css';
import '@daangn/sprout-components-slider/index.css';
import '@daangn/sprout-components-fab/index.css';
import '@daangn/sprout-components-select-box/index.css';
import '@daangn/sprout-components-help-bubble/index.css';
import '@daangn/sprout-components-inline-alert/index.css';

import { IS_IOS } from 'utils/browser';

/** https://github.com/daangn/seed-design/blob/migrate/KARROT_UI_MIGRATION.md */
(function () {
  const el = document.documentElement;
  el.dataset.seed = '';
  el.dataset.seedScaleLetterSpacing = IS_IOS ? 'ios' : 'android';

  const prefersLight = window.matchMedia('(prefers-color-scheme: light)');
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

  if (prefersLight.matches) {
    if ('addEventListener' in prefersLight) {
      prefersLight.addEventListener('change', apply);
    } else if ('addListener' in prefersLight) {
      const addListener = prefersLight['addListener'] as any;
      addListener(apply);
    }
  } else if (prefersDark.matches) {
    if ('addEventListener' in prefersDark) {
      prefersDark.addEventListener('change', apply);
    } else if ('addListener' in prefersDark) {
      const addListener = prefersDark['addListener'] as any;
      addListener(apply);
    }
  }

  function apply() {
    el.dataset.seedScaleColor = prefersDark.matches ? 'dark' : 'light';
  }

  apply();
})();
