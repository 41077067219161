import { CommonImage } from 'constants/image';
import { LazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component';

interface Props extends Omit<LazyLoadImageProps, 'visibleByDefault'> {
  lazy?: boolean;
  fallback?: string;
}

export default function Image({
  lazy = false,
  src,
  alt = '',
  fallback = CommonImage.Empty,
  className,
  ...props
}: Props) {
  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (fallback && e.currentTarget.src !== fallback) {
      e.currentTarget.src = fallback;
    }
  };

  return (
    <LazyLoadImage
      visibleByDefault={!lazy}
      effect="opacity"
      src={src}
      alt={alt}
      className={className}
      onError={(e) => {
        handleError(e);
        props.onError?.(e);
      }}
      {...props}
    />
  );
}
