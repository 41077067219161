import { setContext, setTags, setUser } from '@sentry/react';
import { isWeb } from 'bridge';
import { closeWebview } from 'bridge/router';
import { useDialog } from 'contexts/DialogContext';
import useIsDebug from 'hooks/useIsDebug';
import useSessionState from 'hooks/useSessionState';
import { useUpdateEffect } from 'hooks/useUpdateEffect';
import { useAtom, useAtomValue } from 'jotai';
import { loadable } from 'jotai/utils';
import { DependencyList, EffectCallback, useEffect, useRef, useState } from 'react';
import { daangnErrorState } from 'store/daangnError';
import { debugUserState } from 'store/debug';
import groupState, { groupToLocalStorageEffect } from 'store/group';
import Logger from 'utils/Logger';
import { ActionCountKey, LocalStorage, LocalStorageKey, SessionStorage } from 'utils/Storage';
import { actionToast } from 'utils/actionToast';
import clarity from 'utils/clarity';
import { UserSegmentType } from 'utils/segment';

const useInitAndEffect = (effect: EffectCallback, deps?: DependencyList | undefined) => {
  const init = useRef(false);

  if (init.current === false) {
    effect();
    init.current = true;
  }

  useUpdateEffect(effect, deps);
};

const useInitApp = () => {
  const { user, region, app } = useSessionState();
  const [segment, setSegment] = useState(() => LocalStorage.getUserSegments());
  const group = useAtomValue(groupState);
  const debugUserLoadable = useAtomValue(loadable(debugUserState));
  const isDebug = useIsDebug();
  const daangnError = useAtomValue(loadable(daangnErrorState));
  const { open } = useDialog();

  useAtom(groupToLocalStorageEffect);

  useInitAndEffect(() => {
    if (!isWeb && isDebug) {
      import('v-console').then((module) => module.default());
    }
  }, [isDebug]);

  useInitAndEffect(() => {
    const isDebugUser = debugUserLoadable.state === 'hasData' && debugUserLoadable.data;
    setUser({ id: String(user?.id), username: user?.nickname, isDebugUser });
    setTags({ region: String(region.id) });
    setContext('region', region);

    if (user) {
      setContext('user', {
        id: String(user.id),
        nickname: user.nickname,
        isDebugUser,
        segment,
      });
      Logger.setUser(user, {
        region,
        group,
        segment,
        isDebugUser,
      });
    }

    if (app) {
      Logger.setDevice(app);
    }

    LocalStorage.addActionCount(ActionCountKey.Session);

    setTimeout(() => {
      clarity.init(String(user?.id), {
        referrer: SessionStorage.getReferrer(),
        region: String(region.id),
        is_debug_user: isDebugUser,
        ...Object.entries(segment).map(([key, value]) => ({
          [key]: String(value),
        })),
      });
      segment[UserSegmentType.Broker] && clarity.upgrade('broker');
    }, 500);
  }, [user, region, segment, group, debugUserLoadable]);

  useEffect(() => {
    const unlisten = LocalStorage.addListener(LocalStorageKey.UserSegment, () => {
      setSegment(LocalStorage.getUserSegments());
    });

    return unlisten;
  }, []);

  useEffect(() => {
    if (daangnError.state === 'hasData' && !!daangnError.data) {
      const errorStatus = daangnError.data.status;
      switch (errorStatus.type) {
        case 'alert': {
          open({
            description: errorStatus.message,
            primaryActionLabel: '확인',
            onPrimaryAction: () => {
              closeWebview();
            },
          });
          break;
        }
        case 'toast': {
          actionToast({
            message: errorStatus.message,
          });
          break;
        }
        default: {
        }
      }
    }
  }, [daangnError.state]);
};

export default useInitApp;
