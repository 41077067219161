import type { bizProfileBroker_viewer$key } from '__generated__/bizProfileBroker_viewer.graphql';
import { graphql, readInlineData } from 'relay-runtime';
import type { bizProfileReviewRatings_BizProfileReviewStatisticsV2$key } from '__generated__/bizProfileReviewRatings_BizProfileReviewStatisticsV2.graphql';

type ReviewRatings = {
  averageRating: number;
  totalCount: number;
  ratingCounts: {
    [key: number]: number;
  };
};

export const readViewerBizProfileBroker = (viewerRef: bizProfileBroker_viewer$key | null) => {
  const viewer = readInlineData(
    graphql`
      fragment bizProfileBroker_viewer on KarrotUser @inline {
        id
        bizProfileBroker {
          id
          userPhoneNumber
          role
          autoApproveArticles
          bizProfile {
            id
            localProfileId
            thumbImageUrl
            businessRegistrationNumber
            name
            originalId
            createdAt
            jibunAddress
            licenseOwnerContact
            licenseOwnerName
            licenseRegistrationNumber
          }
        }
      }
    `,
    viewerRef
  );

  return viewer?.bizProfileBroker;
};

export const readBizProfileReviewRatings = (
  reviewStatisticsRef: bizProfileReviewRatings_BizProfileReviewStatisticsV2$key
): ReviewRatings => {
  const reviewStatistics = readInlineData(
    graphql`
      fragment bizProfileReviewRatings_BizProfileReviewStatisticsV2 on BizProfileReviewStatisticsV2
      @inline {
        starRating1Count
        starRating2Count
        starRating3Count
        starRating4Count
        starRating5Count
      }
    `,
    reviewStatisticsRef
  );

  const ratingCounts = {
    5: reviewStatistics.starRating5Count,
    4: reviewStatistics.starRating4Count,
    3: reviewStatistics.starRating3Count,
    2: reviewStatistics.starRating2Count,
    1: reviewStatistics.starRating1Count,
  };

  const totalCount = Object.values(ratingCounts).reduce((acc, count) => acc + count, 0);

  const averageRating =
    totalCount === 0
      ? 0
      : Object.entries(ratingCounts).reduce(
          (acc, [rating, count]) => acc + Number(rating) * count,
          0
        ) / totalCount;

  return {
    averageRating,
    totalCount,
    ratingCounts,
  };
};
