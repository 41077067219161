export const filterType = {
  salesType: '매물 종류',
  tradeType: '거래 유형',
  price: '가격',
  area: '평수',
  floor: '층수',
  buildingApprovalDate: '사용승인일',
  withVideo: '매물 소개 영상',
  options: '매물 조건',
  etc: '기타',
};
export type FilterType = keyof typeof filterType;
