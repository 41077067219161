import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { ActivityComponentType } from '@stackflow/react';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import BoxButton from 'components/Base/BoxButton';
import styled from '@emotion/styled';
import { vars } from '@seed-design/design-token';
import { Box, Center, Flex, Stack } from '@chakra-ui/layout';
import Text from 'components/Base/Text';
import Step3 from './components/Step3';
import Step4 from './components/Step4';
import Step5 from './components/Step5';
import Step6 from './components/Step6';
import StepIndicator from './components/StepIndicator';
import { IconXmarkLine } from '@daangn/react-monochrome-icon';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { RecapUserYearReportQuery } from '__generated__/RecapUserYearReportQuery.graphql';
import useCustomFlow from 'hooks/useCustomFlow';
import { Spinner } from '@daangn/sprout-components-spinner';
import { css } from '@emotion/react';
import { getLastActivity } from 'stackflow/utils';
import usePageLog from 'hooks/usePageLog';
import Logger from 'utils/Logger';

export enum RecapStepKey {
  Intro = 'intro',
  MyShownArticle = 'myShownArticle',
  AllShownArticle = 'allShownArticle',
  AllExpensiveArticle = 'allExpensiveArticle',
  MyFavoriteArticle = 'myFavoriteArticle',
  AllFavoriteArticle = 'allFavoriteArticle',
}

export const AllRecap = {
  readArticleCount: 40,
  tags: ['원투룸', '월세', '아파트', '주차 가능', '반려동물 가능'],
};

const PageRecap: ActivityComponentType = () => {
  const [currentStepIdx, setCurrentStepIdx] = useState(0);
  const { replace, pop } = useCustomFlow();

  const { userYearReport } = useLazyLoadQuery<RecapUserYearReportQuery>(
    graphql`
      query RecapUserYearReportQuery {
        userYearReport {
          readArticleCount
          ...Step1_userYearReport
          ...Step2_userYearReport
          ...Step5_userYearReport
        }
      }
    `,
    {}
  );

  const hasData = useMemo(() => {
    return userYearReport.readArticleCount >= 5;
  }, []);

  useEffect(() => {
    Logger.track('show_recap_step', { step: Steps[currentStepIdx].id });
  }, [currentStepIdx]);

  const Steps = useMemo(() => {
    return [
      {
        id: RecapStepKey.Intro,
        element: <Step1 userYearReportRef={userYearReport} />,
      },
      ...(!!hasData
        ? [
            {
              id: RecapStepKey.MyShownArticle,
              element: <Step2 userYearReportRef={userYearReport} />,
            },
          ]
        : []),
      {
        id: RecapStepKey.AllShownArticle,
        element: <Step3 />,
      },
      {
        id: RecapStepKey.AllExpensiveArticle,
        element: <Step4 />,
      },
      ...(!!hasData
        ? [
            {
              id: RecapStepKey.MyFavoriteArticle,
              element: <Step5 userYearReportRef={userYearReport} />,
            },
          ]
        : []),

      {
        id: RecapStepKey.AllFavoriteArticle,
        element: <Step6 />,
      },
    ];
  }, [hasData]);

  const handleOnClose = () => {
    const lastActivity = getLastActivity();

    if (!lastActivity?.isTop) pop();
    else replace('main', {});
  };

  const handleOnPrev = () => {
    const prevStepIdx = currentStepIdx - 1;

    if (prevStepIdx >= 0) {
      Logger.track('click_recap_step', {
        prev_step: Steps[currentStepIdx].id,
        next_step: Steps[prevStepIdx].id,
        action: 'touch',
      });

      setCurrentStepIdx(prevStepIdx);
    }
  };

  const handleOnNext = (action: 'button' | 'touch') => {
    const nextStepIdx = currentStepIdx + 1;
    if (nextStepIdx < Steps.length) {
      Logger.track('click_recap_step', {
        prev_step: Steps[currentStepIdx].id,
        next_step: Steps[nextStepIdx].id,
        action: action,
      });

      setCurrentStepIdx(nextStepIdx);
    } else {
      replace('recap_result', {});
    }
  };

  useEffect(() => {
    Logger.track('show_recap_step', { step: Steps[currentStepIdx].id });
  }, [currentStepIdx]);

  usePageLog('recap', {
    hasData: hasData,
  });

  return (
    <Wrapper>
      <Stack px={20} pt={12} gap={16}>
        <StepIndicator steps={Steps.length} currentStep={currentStepIdx} />

        <Flex justifyContent="space-between" alignItems="center">
          <Flex gap={6} alignItems="center">
            <img
              src="https://assetstorage.krrt.io/1420322515413545053/f2a742ba-826f-420a-ae04-6f0107b936f7/width=30,height=30.webp"
              width={30}
              height={30}
              alt="logo"
            />
            <Text variant="subtitle2Bold">당근 부동산</Text>
          </Flex>

          <IconXmarkLine
            width={16}
            height={16}
            color={vars.$static.color.staticWhite}
            onClick={handleOnClose}
          />
        </Flex>
      </Stack>
      {/* Step */}
      <ContentWrapper>
        <Suspense fallback={<Loading />}>
          {Steps.map(
            (step, idx) =>
              currentStepIdx === idx && <React.Fragment key={idx}>{step.element}</React.Fragment>
          )}
        </Suspense>

        <ContentClickLeft onClick={handleOnPrev} />
        <ContentClickRight onClick={() => handleOnNext('touch')} />
      </ContentWrapper>
      {/* Button */}
      <Box px={16} py={12}>
        <BoxButton
          onClick={() => handleOnNext('button')}
          width="100%"
          variant="secondary"
          UNSAFE_style={{
            background: vars.$static.color.staticWhite,
            color: vars.$static.color.staticGray900,
          }}
          size="xlarge"
        >
          {currentStepIdx === 0 ? '시작하기' : '다음'}
        </BoxButton>
      </Box>
    </Wrapper>
  );
};

export default PageRecap;

const Loading = () => (
  <Center
    css={css`
      padding: 20px 0px;
    `}
  >
    <Spinner />
  </Center>
);

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);

  background-color: ${vars.$static.color.staticBlack};
  color: ${vars.$static.color.staticWhite};
`;

const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  padding-top: 100px;
`;

const ContentClickRight = styled.div`
  width: 30%;
  height: 100%;

  position: absolute;
  top: 0;
  right: 0;
`;

const ContentClickLeft = styled.div`
  width: 30%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
`;
