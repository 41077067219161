import { devLocalStorage } from 'utils/devStorage';

const ALPHA_LOGIN_INFO = devLocalStorage.getSelectedAlphaLoginInfo();
export const IS_LOCAL =
  process.env.NODE_ENV === 'development' && !!process.env.REACT_APP_USE_LOCAL_MODE;
export const IS_ALPHA =
  process.env.NODE_ENV === 'production' && process.env.REACT_APP_STAGE === 'alpha';
export const IS_DEV = IS_LOCAL || IS_ALPHA;
export const IS_PROD_TEST = IS_ALPHA && !!ALPHA_LOGIN_INFO?.isProduction;
export const IS_PROD = process.env.REACT_APP_STAGE === 'production' || IS_PROD_TEST;
// TODO: stage,build 모두 프로덕션인 경우 네이밍 어떻게 잡을지 고민
export const IS_REAL = !IS_DEV && IS_PROD;
export const OVERRIDE_API_HOST = !IS_REAL
  ? new URLSearchParams(window.location.search).get('override_api_host')
  : null;

export const USE_PERSIST_QUERY = !IS_LOCAL;

const common = {
  SERVICE_NAME: '부동산',
  COMPANY_NAME: '당근마켓',
  BRAND_NAME: '당근',
  CLOUDFLARE_CODE: 'dsdzwhnfwd5uowce',
  CLOUDFLARE_ACCOUNT_ID: 'aad5c82543cd1f267b89737d0f56405e',
  CLOUDFLARE_ACCOUNT_HASH: 'WS-ayVDxD0Eg17NUFMeOWw',
  GA4_TRACKING_ID: 'G-VS6WZS2VFT',
  KARROT_WEBVIEW_HOST: 'karrotwebview',
  SENTRY_DSN: 'https://931fa1e018b04ce28ef63b587760d33e@o24217.ingest.sentry.io/5505146',
  BROKER_FAQ_HOST: 'https://daangn.notion.site/be13356fa2764ec78ed9f76cbd71442a',
  REALTY_KAKAO_URL: 'http://pf.kakao.com/_wrDHG',
  // 도메인 제한으로 로컬 / 알파 분기
  ...(IS_LOCAL
    ? {
        KAKAO_REST_KEY: 'e82ad5c12aa65c75b43c459f997f3ac6',
        KAKAO_MAP_KEY: 'e6e95e607444672527cc9c0100ac5fe7',
      }
    : IS_ALPHA
      ? {
          KAKAO_REST_KEY: '522a9119025b0ab57336c9c66a8ac0b2',
          KAKAO_MAP_KEY: '3147f31e8bf2124276d5308be9acdcbb',
        }
      : {
          KAKAO_REST_KEY: 'fbd1a61fbed8aa50836a621d549d6196',
          KAKAO_MAP_KEY: '390ecb44c4fb3f191d7a351d697212b0',
        }),
} as const;

const event = {
  ARTICLE_HIDDEN: 'REALTY.POST.HIDDEN',
};

const alpha = {
  ...common,
  EVENT: event,
  SERVICE_KEY: 'REALTY_ALPHA',
  AMPLITUDE_KEY: '2f9e294e50d8f4876db58b6b87bef050',
  MIXPANEL_KEY: '42ef13caacd4c8889254f43c3cd2800b',
  APP_HOST: 'https://realty.alpha.karrotwebview.com',
  API_HOST: OVERRIDE_API_HOST || 'https://realty.alpha.kr.karrotmarket.com',
  BIZ_POST_HOST: 'https://business-post.alpha.kr.karrotmarket.com',
  BIZ_ACCOUNT_HOST: 'https://ba.alpha.kr.karrotmarket.com',
  BIZ_PROFILE_HOST: 'https://bizprofile.alpha.karrotwebview.com',
  BIGPICTURE_HOST: 'https://image.alpha.kr.karrotmarket.com',
  BIGSTREAM_HOST: 'https://bigstream.alpha.kr.karrotmarket.com',
  REPORT_HOST: 'https://report.alpha.karrotwebview.com',
  EXPERT_HOST: 'https://yongdal.alpha.karrotwebview.com/expert/home/',
  SHORTCUT_HOST: 'https://shortcut.alpha.kr.karrotmarket.com',
  WEBVIEW_HOST: 'https://webview.alpha.kr.karrotmarket.com',
  REALTY_CEO_HOST: 'https://realty-ceo.alpha.daangn.com',
  REALTY_HOUSE_COUNSELOR_HOST: 'https://realty-house-counselor.alpha.karrotwebview.com',
  APP_SCHEME: 'karrot.alpha',
  PUBLIC_WEB: 'https://alpha.daangn.com',
  PAY_CLIENT_KEY: 'BwFLpQM7GtUt1wy',
  GOOGLE_MAP_KEY: 'AIzaSyDNP1ThOuaBYy-AtA4HtR1c8ZWhgAF1ZcU',
  KARROT_FORM_HOST: 'https://karrot-form-webview.alpha.kr.karrotwebview.com',
} as const;

const production = {
  ...common,
  EVENT: event,
  SERVICE_KEY: 'REALTY',
  AMPLITUDE_KEY: '62c1f25ba7790a3ddb9e125680845768',
  MIXPANEL_KEY: '5c6d8317453bbd03e1477459ce4c1b46',
  APP_HOST: 'https://realty.karrotwebview.com',
  API_HOST: OVERRIDE_API_HOST || 'https://realty.kr.karrotmarket.com',
  BIZ_POST_HOST: 'https://business-post.kr.karrotmarket.com',
  BIZ_ACCOUNT_HOST: 'https://ba.kr.karrotmarket.com',
  BIZ_PROFILE_HOST: 'https://bizprofile.karrotwebview.com',
  BIGPICTURE_HOST: 'https://image.kr.karrotmarket.com',
  BIGSTREAM_HOST: 'https://bigstream.kr.karrotmarket.com',
  REPORT_HOST: 'https://report.karrotwebview.com',
  EXPERT_HOST: 'https://yongdal.karrotwebview.com/expert/home/',
  SHORTCUT_HOST: 'https://shortcut.kr.karrotmarket.com',
  WEBVIEW_HOST: 'https://webview.prod.kr.karrotmarket.com',
  REALTY_CEO_HOST: 'https://realty-ceo.daangn.com',
  REALTY_HOUSE_COUNSELOR_HOST: 'https://realty-house-counselor.karrotwebview.com',
  APP_SCHEME: 'karrot',
  PUBLIC_WEB: 'https://www.daangn.com',
  PAY_CLIENT_KEY: '4XrwJ1daMLzCsLC',
  GOOGLE_MAP_KEY: 'AIzaSyA5Z05cAjtzuvMSxPWrWlc1tM_4so0Guow',
  KARROT_FORM_HOST: 'https://karrot-form-webview.kr.karrotwebview.com',
} as const;

/** Default to dev if not set */
const CONFIG = IS_PROD ? production : alpha;

export default CONFIG;
