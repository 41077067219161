import { StackflowPlugin } from '@stackflow/core';
import { karrotBridge } from 'bridge';
import Logger from 'utils/Logger';

let userInteracted = false;

window.addEventListener(
  'click',
  () => {
    userInteracted = true;
  },
  { once: true }
);

const closeWhenRootPopPlugin = (): StackflowPlugin => {
  return () => ({
    key: 'closeWhenRootPop',
    onBeforePop({ actions }) {
      const stack = actions.getStack();
      const activeActivity = stack.activities.find((activity) => activity.isActive);

      if (activeActivity?.isRoot) {
        actions.preventDefault();

        Logger.track('click_close', {
          user_interacted: userInteracted,
        });

        karrotBridge.closeRouter({});
      }
    },
  });
};

export default closeWhenRootPopPlugin;
