import { ChipToggleButton } from '@daangn/sprout-components-chips';
import FilterSection from 'components/Filter/FilterSection';
import { ArticleOptionNameEnum } from 'types/schemaEnums';
import { xor } from 'lodash-es';
import { Stack, Wrap, WrapItem } from '@chakra-ui/layout';
import { getArticleOptionNameText } from '@daangn/realty-sdk';
import { filterType } from 'constants/filter';
import { Checkbox } from '@daangn/sprout-components-checkbox';

export const OptionTypes: ArticleOptionNameEnum[] = [
  'PARKING',
  'PET',
  'MORTGAGE',
  'ELEVATOR',
  'ROOFTOP',
  'LOFT',
];
const PossibleLabelNeedOption: ArticleOptionNameEnum[] = ['PARKING', 'PET', 'MORTGAGE'];

type Props = {
  options?: readonly ArticleOptionNameEnum[] | null;
  onChangeOptions: (options: readonly ArticleOptionNameEnum[]) => void;
  includeDontKnowOption: boolean;
  onChangeIncludeDontKnowOption: (includeDontKnowOption: boolean) => void;
  moveInNow?: boolean | null;
  onChangeMoveInNow?: (moveInNow: boolean | null) => void;
  useMoveInNow?: boolean;
};

const OptionsSetting = ({
  options,
  moveInNow,
  onChangeOptions,
  onChangeMoveInNow,
  useMoveInNow,
  includeDontKnowOption,
  onChangeIncludeDontKnowOption,
}: Props) => {
  const handleClickOption = (option: ArticleOptionNameEnum) => {
    onChangeOptions(xor(options, [option]));
  };

  const handleClickMoveInOption = () => {
    onChangeMoveInNow?.(moveInNow ? null : true);
  };

  const handleClickDontKnowOption = (isSelected: boolean) => {
    onChangeIncludeDontKnowOption?.(isSelected);
  };

  return (
    <FilterSection title={filterType.options} spacing={8}>
      <Stack spacing={12}>
        <Checkbox
          size="small"
          isSelected={includeDontKnowOption ?? false}
          onChange={handleClickDontKnowOption}
        >
          확인 필요 함께 보기
        </Checkbox>
        <Wrap spacing={8}>
          {OptionTypes.map((option) => {
            const isChecked = options?.includes(option);

            return (
              <WrapItem key={option}>
                <ChipToggleButton
                  size="medium"
                  isSelected={isChecked}
                  onClick={() => handleClickOption(option)}
                >
                  {getArticleOptionNameText(option, { isBroker: false })}
                  {PossibleLabelNeedOption.includes(option) && ' 가능'}
                </ChipToggleButton>
              </WrapItem>
            );
          })}
          {useMoveInNow && (
            <WrapItem>
              <ChipToggleButton
                size="medium"
                onClick={handleClickMoveInOption}
                isSelected={moveInNow ?? false}
              >
                즉시 입주 가능
              </ChipToggleButton>
            </WrapItem>
          )}
        </Wrap>
      </Stack>
    </FilterSection>
  );
};

export default OptionsSetting;
